<template>
  <div
    class="px-7 py-4"
    style="background: #f7f7f7; height: auto; margin-left: -25px"
  >
    <h3>
      Wizfit Daily Performance Report
      <v-icon color="#2C1963" @click="setFlag()" v-if="!this.tableFlag"
        >mdi-plus</v-icon
      >
      <v-icon
        color="#2C1963"
        @click="setFlagCollpase()"
        v-else-if="this.tableFlag"
        style="cursor: pointer"
        >mdi-minus</v-icon
      >
    </h3>
    <br />
    <v-row v-if="this.tableFlag" class="d-flex align-center">
      <v-col cols="auto" class="pl-3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formatedStartDate"
              label="Start Date"
              prepend-inner-icon="mdi-calendar-blank"
              clearable
              v-bind="attrs"
              outlined
              v-on="on"
              dense
              height="43px"
              color="#2C1963"
              style="font-size: 15px; width: 185px"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            no-title
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="auto">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formatedEndDate"
              label="End Date"
              prepend-inner-icon="mdi-calendar-blank"
              clearable
              v-bind="attrs"
              outlined
              v-on="on"
              dense
              height="43px"
              color="#2C1963"
              style="font-size: 15px; width: 185px"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="auto">
        <v-btn
          class="submit-btn"
          dark
          color="#38227A"
          @click="getResourceData()"
          style="margin-top: -25%"
          >Download</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import { GET_WIZFIT_DAILY_CAMPAIGN_STATISTICS_REPORT } from "@/constants/APIUrls";
import { mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
export default {
  name: "WizfitDailyReport",
  components: {},
  data() {
    return {
      dates: [],
      menu: false,
      menu1: false,
      start_date: "",
      end_date: "",
      tableFlag: false,
    };
  },
  created() {},
  computed: {
    formatedStartDate: {
      get() {
        return this.start_date
          ? moment(this.start_date).format("MM-DD-YYYY")
          : "";
      },
      set(value) {
        this.start_date = value
          ? moment(value, "MM-DD-YYYY").format("YYYY-MM-DD")
          : "";
      },
    },
    formatedEndDate: {
      get() {
        return this.end_date ? moment(this.end_date).format("MM-DD-YYYY") : "";
      },
      set(value) {
        this.end_date = value
          ? moment(value, "MM-DD-YYYY").format("YYYY-MM-DD")
          : "";
      },
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),

    setFlag() {
      this.tableFlag = true;
    },
    setFlagCollpase() {
      this.tableFlag = false;
    },

    getResourceData() {
      const self = this;
      self.btnLoading = true;
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };

      const failureHandler = (res) => {
        console.log("Download failed:", res);
        self.btnLoading = false;
      };

      let dataTableParams = {};

      (dataTableParams["start_date"] = this.start_date),
        (dataTableParams["end_date"] = this.end_date),
        // 🔹 Use request_GET_BLOB for file download
        Axios.request_GET_BLOB(
          GET_WIZFIT_DAILY_CAMPAIGN_STATISTICS_REPORT,
          dataTableParams,
          {},
          successHandler,
          failureHandler,
          false,
          true,
          true
        );
    },
  },
  mounted() {},
};
</script>
